.conta2 {
    background-image: url('../Assets/panicch2.png');
    background-size: contain;
    background-position: center center;
    width: 100%;
    height: 100vh;
    
}
.backhome {
    height: 80px;
    position: relative;
    left: 83.8%;
    top: 453px;
    background-size: contain;
    width: 50%;
    background-repeat: no-repeat;
    background-image: url('../Assets/panic\ yellow.png');
}
@media only screen and (max-width: 1800px) and (min-width: 1600px){
    .backhome{
        height: 126px;
        position: relative;
        left: 78.8%;
        top: 721px;
    }
}
@media only screen and (max-width: 2000px) and (min-width: 1800px){
    .backhome{
        height: 170px;
    position: relative;
    left: 79.8%;
    top: 805px;
    }
}

@media (min-width: 2220px) {
    .backhome {
        height: 145px;
        left: 75.8%;
        top: 642px;
        width: 76%;
    }
    .conta2 {
        height: 100vh;
        overflow: hidden;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .conta2 {
        height: 100vh;
        overflow: hidden;
    }
    .backhome {
        height: 60px;
        position: relative;
        left: 75.8%;
        top: 393px;
        background-size: contain;
        width: 50%;
        background-repeat: no-repeat;
        background-image: url('../Assets/panic\ yellow.png');
    }
}