@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&family=Press+Start+2P&display=swap');

.Ycontainer {
  background-image: url('./panic_bg.png');
  background-size: cover;
  background-size: 201%;
  margin-top: 100px;
  /* height: 520vh; */
  height: 3520px;
}


.YcontainerF {
  position: relative;
  margin-top: -720px;
  /* margin-left: 200px; */
}


.row-container {
  position: relative;
  width: 87%;
  left: 6.1%;
  padding: 20px;
  box-sizing: border-box;
}

.row-container h3 {
  margin-left: 15px;
}

.Ycontainer .row-container .items {
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
  /* Ensures 'See More' link doesn't overlap items */
}

.Yitem {
  flex: 1;
  margin: 0 10px;
  /* Adjust spacing between items */
  text-align: center;
}

.Yitem img {
  width: 62%;
  height: auto;
  max-width: 200px;
  /* Adjust the size as needed */
  display: block;
  margin: 0 auto;
}

.Yitem h2 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.YcontainerF .YbtnF {
  display: flex;
  justify-content: space-around;
  position: relative;
  left: -1.5%;
  width: 83%;
  top: 5rem;
  
  /* padding-bottom: 40px; */
}

.YbtnFo {
  display: flex;
  justify-content: space-around;
    position: relative;
    left: 19.9%;
    width: 75%;
    top: -15vh;
  /* padding-bottom: 40px; */
  /* overflow: hidden; */
}

.panicportalF {
  text-decoration: none;
  /* color: black; */
  font-weight: bold;
  border: 1px solid transparent;
  transition: border 0.3s;
  padding: 15px 55px;
  /* height: 14px; */
  background-color: #ce9c11;
  border-radius: 80%;
  /* box-shadow: h-offset v-offset blur spread #f7be22; */
  /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
  font-weight: bold;
  white-space: nowrap;
  position: relative;
  left: 7%;
  /* flex: 1; */
}

.Ycopywrite {
  display: flex;
  justify-content: center;
  position: relative;
  /* padding-top: 140px; */
  height: 40px;
  font-weight: bold;
  top: 10px;
  font-size: 27px;

  font-family: "Anic", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 19px;
  text-shadow: 1px 1px #EBBE0A;
  /* background-image: url('./panic_bg.png'); */
}



.YbtnFo .Don {
  width: 250px;
  height: 295px;
  position: relative;
  right: 140px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Contact.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.YbtnFo .Don:hover {
  background-image: url("../../Assets/Btns/Black-Contact.png");
}
.YbtnFo .QA {
  width: 250px;
  height: 295px;
  
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Q&A.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.YbtnFo .QA:hover {
  background-image: url("../../Assets/Btns/Black-Q&A.png ");
}
.YbtnFo .Abu {
  position: relative;
  width: 250px;
  height: 295px;
  left: -3vw;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-About-us.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.YbtnFo .Abu:hover {
  background-image: url("../../Assets/Btns/Black-About-us.png");
}
.YbtnFo .opensea {
  width: 250px;
  height: 295px;
  
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Opensea.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.YbtnFo .opensea:hover {
  background-image: url("../../Assets/Btns/Black-OpenSea.png");
}






.YbtnF .pensea {
  width: 12rem;
  position: relative;
    top: 4.6vh;
    height: 3.5rem;
  cursor: pointer;
  background-image: url("./Yellow\ Warpcast.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.YbtnF .pensea:hover {
  background-image: url("./Black\ Warpcast.png");
}
.YbtnF .iscord {
  /* width: 250px; */
  /* height: 295px; */
  position: relative;
  left: 6vw;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Discord.png");
  /* background-size: cover; */
  transition: background-image 0.3s ease-in-out;
}


.YbtnF .iscord:hover {
  background-image: url("../../Assets/Btns/Black-Discord.png");
}
.YbtnF .Xx {
  /* width: 250px; */
  /* height: 295px; */
  
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-X.png");
  /* background-size: cover; */
  transition: background-image 0.3s ease-in-out;
}

.YbtnF .Xx:hover {
  background-image: url("../../Assets/Btns/Black-X.png");
}


.ch1 {
  width: 310px;
  height: 295px;
  position: relative;
  top: -220px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.ch1:hover {
  background-image: url("../../Assets/Btns/Clicked-Chapter1.png");
}



.ch2 {
  width: 310px;
  height: 295px;
  position: relative;
  top: -220px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.ch2:hover {
  background-image: url("../../Assets/Btns/Clicked-Chapter2.png");
}

.ch3 {
  width: 310px;
  height: 295px;
  position: relative;
  top: -220px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.ch3:hover {
  background-image: url("../../Assets/Btns/Clicked-Chapter3.png");
}
.ch4 {
  width: 310px;
  height: 295px;
  position: relative;
  top: -220px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.ch4:hover {
  background-image: url("../../Assets/Btns/Clicked-Chapter4.png");
}



.ch5 {
  width: 310px;
  height: 295px;
  position: relative;
  top: -220px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.ch5:hover {
  background-image: url("../../Assets/Btns/Clicked-Chapter5.png");
}

.ch6 {
  width: 310px;
  height: 295px;
  position: relative;
  top: -220px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/Yellow-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.ch6:hover {
  background-image: url("../../Assets/Btns/Clicked-Chapter6.png");
}


.Ycontainer .row-container .see-more {
  position: absolute;
  top: 50px;
  }
  @media only screen and (max-width: 1800px) and (min-width: 1500px) {
    .YbtnFo{
      top: -7vh;
    }.YcontainerF{
      margin-top: -38rem;
    }
  }
  

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .row-container h2 {
    font-size: 15px;
  }
  .Ycontainer {
    position: absolute;
    top: 140px;
    }
  .items {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  /* Ensures 'See More' link doesn't overlap items */
}

.Yitem {
  flex: 1;
  text-align: center;
}
.Yitem h2 {
  font-size: 8px;
  /* color: black; */
}
.Yitem h3 {
  font-size: 11px;
  /* color: black; */
}
.YcontainerF {
  /* background-image: url('./panic_bg.png'); */
  /* background-color: pink; */
  /* height: 10px; */
  position: relative;
  top: 210px;
  width: 45%;
  left: 20px;
  /* margin-right: 90px; */
  margin-top: 570px;
  /* margin-top: 70px; */
  
  /* overflow: hidden; */
}

.YcontainerF .YbtnF {
  display: flex;
  justify-content: space-around;
  position: relative;
  left: 3%;
  width: 247%;
  top: -70px;
  padding-bottom: 40px;
}
.YcontainerF .YbtnFo {
  display: flex;
  justify-content: space-around;
  position: relative;
  left: 19vw;
  width: 147%;
   top: 4vh;
  padding-bottom: 40px;
}

  .Ycontainer{
    /* background-image: url('./panic_bg.png'); */
    /* position: relative; */
    /* bottom: -208px; */
    /* margin-top: 0px; */
    height: 1980px;
    position: relative;
    width: 100%;
    /* padding-left: -25px; */
    /* left: -3.1%; */
    /* color: black; */
  }
  .Ycontainer .row-container {
    position: relative;
    /* width: 85%; */
    /* left: -30px; */
    /* padding-left: -20px; */
    top: -140px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .ch1 {
    position: absolute;
    top: 56px;
    left: 27%;
    margin-left: 7%;
    background-image: url("/public/Yellow-Read-the-story-mobile.png");
    background-size: cover;
    width: 45%;
    height: 29px;
        /* max-height: 40px */
  }
  .ch1:hover, .ch2:hover, .ch3:hover, .ch4:hover, .ch5:hover, .ch6:hover {
    background-image: url("../../Assets/Btns/Yellow\ Read\ the\ story\ 1.png");
  }
  .ch2 {
    position: absolute;
    top: 56px;
    left: 30%;
    margin-left: 7%;
    background-image: url("/public/Yellow-Read-the-story-mobile.png");
    background-size: cover;
    width: 45%;
    height: 29px;
  }
  .ch3 {
    position: absolute;
    top: 56px;
    left: 30%;
    background-image: url("/public/Yellow-Read-the-story-mobile.png");
    background-size: cover;
    width: 45%;
    height: 29px;
    margin-left: 7%;
  }
  .ch4 {
    position: absolute;
    top: 56px;
    left: 30%;
    background-image: url("/public/Yellow-Read-the-story-mobile.png");
    background-size: cover;
    width: 45%;
    height: 29px;
    margin-left: 7%;
  }
  .ch5 {
    position: absolute;
    top: 56px;
    left: 30%;
    background-image: url("/public/Yellow-Read-the-story-mobile.png");
    background-size: cover;
    width: 45%;
    height: 29px;
    margin-left: 7%;
  }
  .ch6 {
    position: absolute;
    top: 56px;
    /* left: 30%; */
    background-image: url("/public/Yellow-Read-the-story-mobile.png");
    background-size: cover;
    width: 45%;
    height: 29px;
    margin-left: 7%;
  }
  
  .YbtnF .Xx{
    width: 626px;
    height: 164px;
    position: relative;
    top: -38px;
    left: -7vw;
    background-size: 139%;
  }
  .YbtnF .iscord{
    width: 751px;
    height: 152px;
    top: -34px;
    left: -6px;
    position: relative;
    background-size: 110%;
  }
  .YbtnFo .opensea {
    width: 12rem;
    height: 8rem;
    left: -14vh;
    top: -1.4vh;
    position: absolute;
  }
  .YbtnF .pensea{
    width: 33rem;
    height: 1.8rem;
    position: relative;
    top: 4.1vh;
    left: 2vw;
    background-size: cover;
  }
  .YbtnFo .Abu{
    width: 11rem;
        height: 8rem;
        position: absolute;
        top: -0.5vh;
        left: 5vw;
  }
  .YbtnFo .QA{
    /* width: 500px; */
    height: 161px;
    position: relative;
    top: 82px;
    left: -9px;
  }
  .YbtnFo .Don{
    width: 12rem;
    height: 9rem;
    position: relative;
    top: -1.5vh;
    left: 27vw;
    background-size: cover;
  }
  .YcontainerF .btnxs {
    display: flex;
    justify-content: space-around;
    position: relative;
    left: -9%;
    width: 210%;
    top: 150px;
    padding-bottom: 40px;
  }
  .YbtnFo {
    display: flex;
    justify-content: space-around;
    position: relative;
    left: 2%;
    
    /* width: 95%; */
    top: -90px;
    padding-bottom: 40px;
}
.Ycopywrite {
  display: flex;
  justify-content: center;
  position: relative;
  /* padding-top: 140px; */
  height: 40px;
  font-weight: bold;
  top: 220px;
  left: 4px;
  font-size: 15px;
  /* background-image: url('./panic_bg.png'); */
}
.Ycontainer .row-container .see-more {
  position: absolute;
  top: 152px;
  }
  /* .YbtnF, .YbtnFo {
    width: 98%;
  } */
  

  .Yitem .asizebr {

    width: 70px;
  }
  .Yitem .asizeb {
    width: 70px;
    display: flex;
    justify-content: space-around;

    /* height: 420px; */
  }

  .Yitem .asizea {
    width: 100%;
    /* height: 50px; */
    /* max-width: 200px; */
    /* Adjust the size as needed */
    /* display: block; */
    /* margin: 0 auto; */
  }
  .Yitem img {
    width: 70px;
    height: auto;
    max-width: 200px; /* Adjust the size as needed */
    display: block;
    margin: 0 auto;
  }
 
}
@media only screen and (max-width: 1999px) and (min-width: 1900px) {
  .YbtnFo{
    top: -4vh;
  }
}

@media only screen and (max-width: 4000px) and (min-width: 2800px) {
  .YbtnFo {
    left: 14.9%;
    width: 75%;
    top: 0vh;
  }
}

