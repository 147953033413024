@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&family=Press+Start+2P&display=swap');


.conta1 {
    background-image: url('../Assets/chpanic.GIF');
    background-size: contain;
    /* background-position: center center; */
    width: 100%;
    height: 156vh;
    /* background-color: #ff501e; */
    /* overflow: hidden; */

    /* font-family: 'Anic'; */
}
.introP {
  position: absolute;
  /* top: 427px; */
  top: 263px;
  width: 90%;
  font-weight: bold;
  left: 120px;
  color: #FFD21E;
  font-family: "Anic", sans-serif;
  font-optical-sizing: auto;
  /* font-weight: 700; */
  font-style: normal;
  font-size: 22px;
  /* text-shadow: 1px 1px #EBBE0A; */
}
@media only screen and (max-width: 1800px) and (min-width: 1600px){
  .introP{
     top: 427px;
  }
  }
.CHcontainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    position: relative;
    top: 1008px;
    width: 20%;
    left: 14%;
    /* background-color: orange; Adjust this to match the background color in your image */
  }
  
  .box {
    padding: 20px;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */
    text-align: center;
    top: 78px;
    position: relative;
    left: -66px;
  }
  .boxb {
    padding: 20px;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */
    text-align: center;
    position: relative;
    top: 77px;
    left: 111px;

  }
  @media only screen and (max-width: 1800px) and (min-width: 1600px){
    .boxb{
      top: -201px;
      left: 101px;
    }
    .image{
      width: 85%;
      height: 65%;
      margin-bottom: 10px;
    }
  }
  
  .image {
    width: 120%;
    height: 70%;
    margin-bottom: 10px;
  }
    @media only screen and (max-width: 2000px) and (min-width: 1800px){
  .boxb .image{
    width: 124%;
    height: 75%;
  }
  .boxb{
    top: -11px;
    left: 156px;
  }
  .box .image{
    width: 124%;
    height: 75%;
  }
  .box{
    top:-11px;
    left: -61px
  }
  .ch1backhome{
    left: 78%;
    top: 1079px
}
    }

      @media only screen and (max-width: 1800px) and (min-width: 1600px){
        .boxb .image{
          width: 116%;
          height: 70%;
        }
        .boxb{
          top: 77px;
          left: 89px;
        }
        .box .image{
          width: 120%;
          height: 71%;
      
        }
        .box{
          top: 79px;
          left: -91px;
        }
        .ch1backhome{
          left: 78%;
          top: 1079px
      }
          }
  
  .text {
    color: yellow; /* Adjust this to match the text color in your image */
    /* font-size: 18px; */
    font-weight: bold;
    position: relative;
    top: -13px;
    /* text-transform: uppercase; */
    font-weight: bold;
    /* left: 219px; */
    color: #FFD21E;
    font-family: "Anic", sans-serif;
    /* font-optical-sizing: auto; */
    /* font-weight: 700; */
    font-style: normal;
    font-size: 19px;
    /* text-shadow: 1px 1px #EBBE0A; */
  }
  .ch1backhome {
    height: 11%;
    position: absolute;
    left: 65%;
    overflow: hidden;
    top: 1135px;
    background-size: contain;
    width: 75%;
    background-repeat: no-repeat;
    background-image: url('../Assets/panic\ yellow.png');
}
@media only screen and (max-width: 1800px) and (min-width: 1600px){

  .ch1backhome {
    
    left: 69%;
    top: 1168px;
   
}

}
@media (min-width: 2220px) {
  .CHcontainer {
    left: 6.3%;
    top: 670px;
  }
  .ch1backhome {
    top: 835px;
    left: 49%;
    width: 55%;
    height: 15%;
  }
  .image {
    width: 94%;
    height: 73%;
    margin-bottom: 10px;
  }
  .box {
    padding: 20px;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */
    text-align: center;
    top: 104px;
    position: relative;
    left: -9px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .ch1backhome {
    height: 70px;
    position: absolute;
    left: 78.8%;
    overflow: hidden;
    top: 640px;
    background-size: contain;
    width: 40%;
    background-repeat: no-repeat;
    background-image: url('../Assets/panic\ yellow.png');
}
  .text {
    font-size: 11px;
  }
  .image {
    width: 70px;
    height: 70px;
  }
    .conta1{
        background-image: url('../Assets/Chapter1bg.gif');
        background-size: cover;
        background-size: 133%;
        background-position-x: 3px;
        background-repeat: no-repeat;
        /* background-position: center center; */
        width: 100%;
        height: 150vh;
        overflow-x: hidden;
    }
    .introP{
      left: 35px;
      top: 164px;
      width: 77%;
      font-size: 13px;
    }
    .CHcontainer {
        top: 350px;
        padding-bottom: 330px;
        width: 70%;
        left: 2%;
        
      }

      .box {
        width: 48px;
        height: 48px;
        position: relative;
        left: -15px;
        top: 248px;
    }
      
        .boxb {
          width: 48px;
        height: 48px;
        position: relative;
        top: 248px;
        left: -54px;
      }
      .text {
        top: -16px;
        left: -17px;
        /* padding-right: 9px; */
      }
}
@media only screen and (max-width: 3200px) and (min-width: 2880px) {
.introP{
  top: 822px;
  /* font-size: 1.7rem; */
  left: 219px;
  font-size: 38px;
}
.box{
  padding: 20px;
  /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */
  text-align: center;
  top: 1042px;
  position: relative;
  left: 134px;
}
.image{
  width: 138%;
  height: 84%;
}
.boxb{
  padding: 20px;
  /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */
  text-align: center;
  position: relative;
  top: 1042px;
  left: 547px;
}
.ch1backhome{
  top: 1800px;
  left: 75%;
}
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
  .introP{
    position: absolute;
    top: 293px;
    width: 90%;
    font-weight: bold;
    left: 185px;
    color: #FFD21E;
    font-family: "Anic", sans-serif;
    font-optical-sizing: auto;
    /* font-weight: 700; */
    font-style: normal;
    font-size: 13px;
    /* text-shadow: 1px 1px #EBBE0A; */
    position: relative;
  }
  .box{
    position: relative;
    top: -543px;
    left: 40px;
  } 
  .boxb{
    position: relative;
        top: -543px;
        left: 157px;
  }
  .image{
    width: 135%;
    height: 73%;
  }
  .text{
    font-size: 11px;
  }
.ch1backhome{
  left: 74%;
    top: 798px;
}
}