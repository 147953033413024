/* Navbar.css */
.ynavbar {
  /* background-color: #333; */
  color: white;
  padding: 10px 20px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}



.navbar-buttons {
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
}

.mkt {
  width: 15rem;
    height: 4rem;;
    position: relative;
    top: -9.7vh;
    cursor: pointer;
    left: -2vh;
  /* margin: 50px; */
  background-image: url("./mktp.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}


.mkt:hover {
  background-image: url("./Black-Marketplace.png");
}

.col {
  width: 260px;
  height: 175px;
  position: relative;
  top: -90px;
  cursor: pointer;
  /* margin: 50px; */
  background-image: url("./Yellow-Collection.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.col:hover {
  background-image: url("./Black-Collection.png");
}



.mint {
  width: 18rem;
  height: 4rem;
    position: relative;
    top: -9.7vh;
    left: -4rem;
    cursor: pointer;
  /* margin: 50px; */
  background-image: url("./Yellow-Mint-Now.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.mint:hover {
  background-image: url("./Black-Mint-Now.png");
}
.shop {
  width: 13rem;
  height: 3.5rem;
  position: relative;
  top: -5vh;
  cursor: pointer;
  margin: 1.5rem;
  background-image: url("./Yellow\ Shop.png");
  background-size: cover;
  transition: background-image 0.3sease-in-out;
}

.shop:hover {
  background-image: url("./Black\ Shop.png");
}
.linktree {
  width: 12rem;
  height: 3.4rem;
  position: relative;
  top: -5vh;
    cursor: pointer;
    margin: 1.5rem;
  background-image: url("./Yellow\ Linktree.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.linktree:hover {
  background-image: url("./Black\ Linktree.png");
}
.walletchecker {
      width: 16rem;
      height: 3rem;
    position: relative;
    top: -5vh;
    cursor: pointer;
    margin: 1.5rem;
  cursor: pointer;
  margin: 1.5rem;
  background-image: url("./Yellow-wallet.png");
  background-size: cover;
  transition: background-image 0.3sease-in-out;
}

.walletchecker:hover {
  background-image: url("./Black\ Wallet\ Checker.png");
}

.con {
  width: 260px;
  height: 175px;
  position: relative;
  top: -90px;
  cursor: pointer;
  cursor: pointer;
  /* margin: 50px; */
  background-image: url("./Yellow-Connect.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.con:hover {
  background-image: url("./Black-Connect.png");
}


.navbar-button {
  text-decoration: none;
  color: black;
  margin: 10px 65px;
  border: 1px solid transparent;
  transition: border 0.3s;
  padding: 9px 15px;
  /* height: 14px; */
  background-color: #ce9c11;
  border-radius: 80%;
  /* box-shadow: h-offset v-offset blur spread #f7be22; */
  /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
  font-weight: bold;
  white-space: nowrap;

}

.navbar-button:hover {
  background-color: black;
  color: #ce9c11;
  border: 1px solid blackZZ;

}

.navbar-button:hover {
  border: 1px solid white;
}

.navbar-menu-icon {
  display: none;
}

.show {
  margin-bottom: 104px;
}
@media only screen and (max-width: 2800px) and (min-width: 1966px) {
.mkt{
  top: -5vh;

}
.mint{
  top: -5vh;

}
}


  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .navbar-container {
      /* display: block; */
      height: 175px;
      margin-top: 20px;
      /* justify-content: end; */
      /* align-items: center; */
    }
    .linktree{
      width: 7rem;
      height: 2.2rem;
      position: absolute;
      top: 5.5vh;
      left: 5vh;
        margin: 0rem;
    }
    .shop{
      width: 7.5rem;
        height: 1.9rem;
        position: absolute;
        top: 5.7vh;
        left: 49vw;
        margin: 0rem;
        z-index: 100;

    }
    .walletchecker{
      width: 8.65rem;
        height: 1.7rem;
        position: absolute;
        top: 9.6vh;
        left: -5vh;
        /* margin: 0rem; */
    }
    
    .mkt {
      width: 9rem;
        height: 2.5rem;
        position: absolute;
        top: 11.75vh;
        left: 14.5vh;



    }
  
    .col {
      width: 38%;
        height: 20vh;
      /* position: relative; */
      /* top: -67px; */
      left: 4px;
    }
  
    .mint {
      width: 9rem;
      height: 2.5rem;
      position: absolute;
      top: 11.75vh;
      left: 30vh;


    }
  
    .con {
      width: 80%;
      height: 60px;
      position: relative;
      /* top: -207px; */
      /* left: 137px; */
    }
  }
  
@media (max-width: 768px) {
  .navbar-buttons {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .navbar-buttons.open {
    display: flex;
  }

  .navbar-button {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
  }

  .navbar-button:hover {
    border: none;
    background-color: #444;
  }

  .navbar-menu-icon {
    display: block;
    cursor: pointer;
    color: white;
    font-size: 24px;
    position: relative;
  }
}


@media only screen and (max-width: 3900px) and (min-width: 2500px) {
  .ynavbar{
    position: relative;
    top: 5vh;
  }
  .mkt {
    top: -4.7vh;

    }
    .mint {
      top: -4.7vh;
  
      }
}