

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');


body {
    overflow-x: hidden;
}
.YcontainerFirst .YmainPage {
    /* color: black; */
    /* height: 140vh; */
    background-color: #FFD21E;
}
.navbar-log img {
    width: 30%;
    /* height: 120px; */
    
}
.YheroSection {
    padding-bottom: 120px;
}

.navbar-log {
    margin-bottom: 350px;
    font-size: 104px;
    font-weight: bold;
    text-decoration: none;
    /* color: black; */
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    margin-top: 120px;
  font-style: italic;
  position: relative;
    top: 10px;
  }
  .YcontainerFirst .mainPage {
    position: relative;
    top: 350px;
    background-image: url('./panic_bg.png');
    background-size: cover;
    background-size: 201%;
  }
  .Yshow {
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    padding-top: 4px;
    position: relative;
    top: -70px;
    margin-bottom: 447px;
    font-family: 'Anic', sans-serif;
  }
  .write-up {
    position: relative;
    top: 120px;
    justify-content: center;
    text-align: center;

    margin-bottom: 61px;
  }
  .containerFirst img {
    width: 30%;
  }
  .YheroSection .navbar-log .logo {
    margin-bottom: 48px;
    width: 25%;
  }
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .YcontainerFirst {
        width: 100%;
        overflow-x: scroll;
        /* color: black; */
        overflow-y: scroll;
        /* z-index: -1000; */
        /* position: relative; */
        height: 300vh;
    }
    .navbar-log {
        margin-top: 0px;
        /* padding-bottom: 110px; */
        position: relative;
        top: 50px;
    }
    .YheroSection {
        height: 48vh;
    }
    .navbar-log img{
        margin-top: 0px;
    position: relative;
    top: -36px;
    width: 60%;
    /* margin-bottom: 220pc; */
    }
    .Yshow {
        font-size: 12px;
        cursor: pointer;
        font-weight: bold;
        padding-top: 4px;
        position: relative;
        top: -90px;
      }
      .write-up {
        position: relative;
        top: 120px;
        justify-content: center;
        text-align: center;
    
        margin-bottom: 61px;
      }
      .heroSection {
        height: 10vh;
      }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    body {
        font-size: 16px;
    }

    .wrapper {
        width: 750px;
        margin: 0 auto;
    }
    .example {
        color: rgb(179, 255, 0);
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    body {
        font-size: 18px;
    }

    .wrapper {
        width: 970px;
        margin: 0 auto;
    }
    .example {
        color: red;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    body {
        font-size: 20px;
    }

    .wrapper {
        /* width: 1170px; */
        margin: 0 auto;
    }
    .example {
        color: green;
    }
}

/* Portrait and landscape phones (320px and up) */
@media (min-width: 320px) {
    .example {
        font-size: 1rem;
        color: purple;
    }
}
@media (min-width: 376px) and (max-width: 479px) {
    .example {
        font-size: 1rem;
        color: yellow;
    }
}

/* Landscape phones (480px and up) */
@media (min-width: 480px) {
    .example {
        font-size: 1.1rem;
        color: pink;
    }
}

/* Tablets (768px and up) */
@media (min-width: 768px) {
    .example {
        font-size: 1.2rem;
        color: aqua;
    }
}

/* Desktops and laptops (1024px and up) */
@media (min-width: 1024px) {
    .example {
        font-size: 1.3rem;
        color: brown;
    }
}

/* Large desktops (1280px and up) */
@media (min-width: 1280px) {
    .example {
        font-size: 1.4rem;
        color: chocolate;
    }
}

/* Extra large desktops (1600px and up) */
@media (min-width: 1600px) {
    .example {
        font-size: 1.5rem;
        color: cadetblue;
    }
}
