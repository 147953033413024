.conta3 {
    background-image: url('../Assets/panicch3.png');
    background-size: contain;
    background-position: center center;
    width: 100%;
    height: 100vh;
    
}
@media (min-width: 2220px) {
    .backhome {
        height: 145px;
        left: 75.8%;
        top: 642px;
        width: 76%;
    }
    .conta3 {
        height: 100vh;
        overflow: hidden;
    }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .conta3 {
        height: 100vh;
        overflow: hidden;
    }
    .backhome {
        height: 60px;
        position: relative;
        left: 75.8%;
        top: 393px;
        background-size: contain;
        width: 50%;
        background-repeat: no-repeat;
        background-image: url('../Assets/panic\ yellow.png');
    }
}