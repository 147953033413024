/* Navbar.css */
.navbar {
    background-color: black;
    color: white;
    padding: 10px 20px;
    position: relative;
    top: -2vh;
  }
  
  .navbar-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    display: flex;
    justify-content: space-evenly;
  }
  .mkt_g {
    width: 18rem;
    height: 4rem;
    position: relative;
    top: -5.5vh;
      cursor: pointer;
      left: -2vh;
    /* margin: 50px; */
    background-image: url("../Home/NavBar/Black-Marketplace.png");
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
  }
  
  
  .mkt_g:hover {
    background-image: url("../Home/NavBar/mktp.png");
  }
  
 
  
  
  
  .mint_g {
    width: 18rem;
    height: 4rem;
    position: relative;
    top: -5.5vh;
      left: -4rem;
      cursor: pointer;
    /* margin: 50px; */
    background-image: url("../Home/NavBar/Black-Mint-Now.png");
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
  }
  
  .mint_g:hover {
    background-image: url("../Home/NavBar/Yellow-Mint-Now.png");
  }
  .shop_g {
    width: 13rem;
    height: 3.5rem;
    position: relative;
    top: -5vh;
    cursor: pointer;
    margin: 1.5rem;
    background-image: url("../Home/NavBar/Black\ Shop.png");
    background-size: cover;
    transition: background-image 0.3sease-in-out;
  }
  
  .shop_g:hover {
    background-image: url("../Home/NavBar/Yellow\ Shop.png");
  }
  .linktree_g {
    width: 12rem;
    height: 3.7rem;
    position: relative;
    top: -5vh;
      cursor: pointer;
      margin: 1.5rem;
    background-image: url("../Home/NavBar/Black\ Linktree.png");
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
  }
  
  .linktree_g:hover {
    background-image: url("../Home/NavBar/Yellow\ Linktree.png");
  }
  .walletchecker_g {
    width: 15rem;
      height: 2.9rem;
      position: relative;
      top: -5vh;
      cursor: pointer;
      margin: 1.5rem;
    cursor: pointer;
    margin: 1.5rem;
    background-image: url("../Home/NavBar/Black\ Wallet\ Checker.png");
    background-size: cover;
    transition: background-image 0.3sease-in-out;
  }
  
  .walletchecker_g:hover {
    background-image: url("../Home/NavBar/Yellow-wallet.png");
  }
 
 
  
  .navbar-buttons {
    display: flex;
    gap: 20px;
    /* justify-content: space-between; */
  }
  
  .navbar-button {
    text-decoration: none;
    color: black;
    margin: 10px 65px;
    border: 1px solid transparent;
    transition: border 0.3s;
    padding: 9px 15px;
    /* height: 14px; */
    background-color: #ce9c11;
    border-radius: 80%;
    /* box-shadow: h-offset v-offset blur spread #f7be22; */
    /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
    font-weight: bold;
    white-space: nowrap;
    
  }
  .navbar-button:hover {
    background-color: black;
    color: yellow;
    border: 1px solid black;
  
  }
  
  .navbar-button:hover {
    border: 1px solid white;
  }
  
  .navbar-menu-icon {
    display: none;
  }
  .show {
    margin-bottom: 104px;
  }
  .navbar .navbar-container .Gmkt {
    width: 260px;
    height: 175px;
    position: relative;
    top: -90px;
    cursor: pointer;
    /* margin: 50px; */
    background-image: url("./Assets/Black-Marketplace.png");
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
  }
  
  .navbar .navbar-container .Gmkt:hover {
    background-image: url("./Assets/Yellow-Marketplace.png");
  }
  .navbar .navbar-container .Gcol {
    width: 260px;
    height: 175px;
    position: relative;
    top: -90px;
    cursor: pointer;
    /* margin: 50px; */
    background-image: url("./Assets/Black-Collection.png");
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
  }
  
  .navbar .navbar-container .Gcol:hover {
    background-image: url("./Assets/Yellow-Collection.png");
  }
  .navbar .navbar-container .Gmint {
    width: 260px;
    height: 175px;
    position: relative;
    top: -90px;
    cursor: pointer;
    /* margin: 50px; */
    background-image: url("./Assets/Black-Mint-Now.png");
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
  }
  
  .navbar .navbar-container .Gmint:hover {
    background-image: url("./Assets/Yellow-Mint-Now.png");
  }
  .navbar .navbar-container .Gcon {
    width: 260px;
    height: 175px;
    position: relative;
    top: -90px;
    cursor: pointer;
    cursor: pointer;
    /* margin: 50px; */
    background-image: url("./Assets/Black-Connect.png");
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
  }
  
  .navbar .navbar-container .Gcon:hover {
    background-image: url("./Assets/Yellow-Connect.png");
  }

  @media only screen and (max-width: 1800px) and (min-width: 1500px) {
    .navbar-container{
      position: relative;
      top: 3rem;

    }
    .mkt{
      top: -5vh;
    }
    .mint{
      top: -5vh;
    }
  }
   @media only screen and (max-width: 767px) and (min-width: 320px) {
    .navbar-container {
      display: block;
      height: 175px;
      margin-top: 30px;
      position: relative;
        width: 100%;
        margin-bottom: 13vh;
      /* justify-content: end; */
      /* align-items: center; */
    }
    .linktree_g{
      width: 8rem;
      height: 2.5rem;
      position: absolute;
      top: 5vh;
      left: 6vh;
      margin: 0rem;
    }
    .shop_g{
      width: 8.3rem;
      height: 2.2rem;
      position: absolute;
      top: 5.4vh;
      left: 26vh;

        margin: 0rem;
        z-index: 100;

    }
    .walletchecker_g{
      width: 8.65rem;
      height: 1.7rem;
      position: absolute;
      top: 10vh;
      left: -5vh;
        /* margin: 0rem; */
    }
    
    .mkt_g {
      width: 9rem;
        height: 2.2rem;
        position: absolute;
        top: 12.5vh;
        left: 15vh;

    }
  
    
  
    .mint_g {
      width: 9rem;
        height: 2.2rem;
        position: absolute;
        top: 12.5vh;
     left: 58vw;
      /* z-index: 100; */


    }
    .navbar .navbar-container .Gmkt {
      /* display: none; */
      width: 80%;
      height: 79px;
      position: relative;
      /* top: 32px; */
      overflow: hidden;
    }
  
    .navbar .navbar-container .Gcol {
      width: 80%;
      height: 79px;
      /* position: relative; */
      /* top: -67px; */
      /* left: 226px; */
    }
  
    .navbar .navbar-container .Gmint {
      width: 80%;
      height: 79px;
      position: relative;
      /* top: -134px; */
      /* left: -37px; */
    }
  
    .navbar .navbar-container .Gcon {
      width: 80%;
      height: 60px;
      position: relative;
      /* top: -207px; */
      /* left: 137px; */
    }
  }
  
  @media (max-width: 768px) {
    .navbar-buttons {
      display: none;
      flex-direction: column;
      width: 100%;
    }
  
    .navbar-buttons.open {
      display: flex;
    }
  
    .navbar-button {
      width: 100%;
      text-align: left;
      padding: 15px 20px;
    }
  
    .navbar-button:hover {
      border: none;
      background-color: #444;
    }
  
    .navbar-menu-icon {
      display: block;
      cursor: pointer;
      color: white;
      font-size: 24px;
      position: relative;
    }
  }
  @media only screen and (max-width: 1999px) and (min-width: 1900px) {
    .navbar-container{
      top: 6vh;
      position: relative;
    }
    .mkt{
      width: 17rem;
      height: 4rem;
      position: relative;
      top: -4.8vh;
    }
    .mint{
      width: 20rem;
      height: 4.3rem;
      position: relative;
      top: -4.7vh;
    }
  }
  @media only screen and (max-width: 2800px) and (min-width: 1966px) {
    .navbar-container{
      position: relative;
      top: 5vh;
      width: 78%;
      left: 12%;
    }
    .mkt_g{
      top: -5vh;
    }
    .mint_g{
      top: -5vh;
    }
  }
  