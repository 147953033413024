body {
    font-family: 'Anic', sans-serif;
  }

.about-us-container {
    padding: 20px;
    background-color: #ffcb00;
    height: 250vh;
    padding-left: 80px;
    background-size: auto;
    background-image: url('./panic_bg.png');
    /* font-family: 'Arial', sans-serif; */
  }
  
  .about-us-container h2 {
    /* text-align: left; */
    margin-left: 66px;
    margin-bottom: 40px;
    /* font-family: 'Anic', sans-serif; */
  }
  .ch1backhome1 {
    height: 150px;
    position: absolute;
    left: 73.8%;
    overflow: hidden;
    top: 1200px;
    background-size: contain;
    width: 75%;
    background-repeat: no-repeat;
    background-image: url('../Chapters/Assets/panic\ yellow.png');
}
  
  
  .profile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* border: 1px solid #000; */
    padding: 10px;
    /* background-color: #fff; */
    border-radius: 8px;
  }
  
  .profile-pic {
    width: 22%;
    height: 320px;
    margin-right: 20px;
    position: relative;
    top: -25px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  
  .profile-pic.hitch {
    background-image: url('./Hitch\ 0.png');
  }
  
  .profile-pic.spicyD {
    background-image: url('./SpicyD\ 0.png');
  }
  
  .profile-pic.spiral {
    background-image: url('./Spiral\ 0.png');
  }
  
  .profile-info {
    flex: 1;
  }
  
  .profile-info h3 {
    margin: 0;
    margin-bottom: 5px;
  }
  
  .profile-info p {
    width: 80%;
    margin: 0;
    word-spacing: 12px;
    line-height: 30px;
  }
  
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .ch1backhome1 {
      height: 90px;
      position: absolute;
      left: 75.8%;
      overflow: hidden;
      top: 2314px;
      background-size: contain;
      width: 75%;
      background-repeat: no-repeat;
      background-image: url('../Chapters/Assets/panic\ yellow.png');
  }
    .about-us-container{
        padding-left: 32px;
        /* width: 70%; */
        background-size: contain;
        height: 450vh;
    }
    .profile-info p {
        width: 100%;
    }
    

    .profile {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .profile-pic {
      /* width: 40px; */
      /* height: 40px; */
      margin-bottom: 10px;
      margin-left: 15%;
      width: 70%;
      justify-content: center;
    }
  }
  