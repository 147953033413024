.form-container{
    background-color: rgb(255, 210, 30);
    width: 89%;
    height: 75vh;
    position: relative;
    bottom: 15vh;
}
.popup-message{
    position: relative;
    align-content: center;
    left: 26%;
    top: 17vh;
}

@media only screen and (max-width: 2100px) and (min-width: 1800px) {
    .form-container {
        bottom: -6vh;
    }
    .close-btn1 {
        left: 54vw;
        top: 7rem;
    }
    .address1{
        left: 37vw;

    }
}
/* @media only screen and (max-width: 767px) and (min-width: 320px) { */
@media only screen and (min-width: 320px) and (max-width: 480px) and (min-height: 568px) {
}
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        /* Retina iPhone styles */
    .form-container{
        bottom: -4vh;
        height: 54vh;
        width: 93vw;
        left: -17%;

    }
    .close-btn1{
        left: 60vw;
        z-index: 10;
        top: 3rem;
    }
    .address1 .addressinput{
        width: 19rem;
        left: 18vw;
    }
    .buttonform1 {
        top: 28vh;
        left: 23vw;
    }
    .popup-message{
        left: 4%;
    top: 32vh;
    width: 100%;
    font-size: 0.8rem;
    }
    .address1{
        left: 46vw;
    }
}