body {
  /* background-color: black; */
}
.Gcontainer {
    background-image: url('./Assets/darkpanic_bg.png');
    background-size: contain;
     height: 500vh;
  /* max-height: 3420px; */
  /* margin-bottom: 1400px; */
  }
.GcontainerF {
  /* background-image: url('./Assets/darkpanic_bg.png'); */
    height: 0vh;
    position: relative;
    /* top: -1338px; */
    top: -391px;
    /* overflow: hidden; */
  }
  @media only screen and (max-width: 1800px) and (min-width: 1600px){
    .Gcontainer{
      height: 316vh;
    }
    .GcontainerF {
      /* background-image: url('./Assets/darkpanic_bg.png'); */
        height: 0vh;
        position: relative;
        top: -1338px;
        /* top: -381px; */
        /* overflow: hidden; */
      }
}
  @media (min-width: 2220px) {
    .Gcontainer {
      background-image: url('./Assets/darkpanic_bg.png');
      background-size: contain;
       height: 340vh;
    /* max-height: 3420px; */
    /* margin-bottom: 1400px; */
    }
}
.row-container {
    position: relative;
    width: 87%;
    left: 6.1%;
    padding: 20px;
    box-sizing: border-box;
  }
  .row-container h3 {
    margin-left: 15px;
  }
  @media only screen and (max-width: 767px) and (min-width: 320px){
    .row-container h3 {
      margin-left: 15px;
    }
  }
  .see-more {
    position: absolute;
    top: 65px;
    left: 75%;
    position: absolute;
  right: 20px;
  }

  
  
  .see-more a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    padding: 10px 25px 15px 25px;
    border-radius: 50%;
    background-color: #ce9c11;
  }
  
  .items {
    display: flex;
    justify-content: space-between;
    margin-top: 40px; /* Ensures 'See More' link doesn't overlap items */
  }
  
  .item {
    flex: 1;
    margin: 0 10px; /* Adjust spacing between items */
    text-align: center;
  }
  
  .item img {
    width: 62%;
    height: auto;
    max-width: 200px; /* Adjust the size as needed */
    display: block;
    margin: 0 auto;
  }
  
  .item h2 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  .GbtnF {
    display: flex;
    justify-content: space-around;
    position: relative;
    left: 5.8%;
    width: 83%;
    top: 230px;
    padding-bottom: 40px;
  }
  .GbtnFo {
    display: flex;
    justify-content: space-around;
    position: relative;
    left: 12.9%;
    width: 79%;
    top: 70px;
    padding-bottom: 40px;
}
.panicportalF {
  text-decoration: none;
color: black;
font-weight: bold;
  border: 1px solid transparent;
  transition: border 0.3s;
  padding: 15px 55px;
  /* height: 14px; */
  background-color: #ce9c11;
  border-radius: 80%;
  /* box-shadow: h-offset v-offset blur spread #f7be22; */
  /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
  font-weight: bold;
  white-space: nowrap;
  position: relative;
  left: 7%;
  /* flex: 1; */
}
.copywrite {
  display: flex;
  justify-content: center;
  position: relative;
  /* padding-top: 140px; */
  height: 40px;
  font-weight: bold;
  top: 70px;
  font-size: 27px;


   /* color: #FFD21E; */
   font-family: "Anic", sans-serif;
   font-optical-sizing: auto;
   
   font-style: normal;
   font-size: 19px;
   /* text-shadow: 1px 1px #EBBE0A; */
  /* background-image: url('./panic_bg.png'); */
}


.Don {
  width: 269px;
  height: 295px;
  position: relative;
  left: -140px;
  cursor: pointer;
  background-image: url("./Assets/Black-Contact.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.Don:hover {
  background-image: url("./Assets/Yellow-Contact.png");
}
.QA {
  width: 250px;
  height: 295px;
  
  cursor: pointer;
  background-image: url("./Assets/Black-Q&A.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.QA:hover {
  background-image: url("./Assets/Yellow-Q&A.png ");
}

.Abu {
  width: 269px;
  height: 295px;
  
  cursor: pointer;
  background-image: url("./Assets/Black-About-us.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}


.Abu:hover {
  background-image: url("./Assets/Yellow-About-us.png");
}






.opensea {
  width: 250px;
    height: 295px;
    cursor: pointer;
  background-image: url("./Assets/Black-OpenSea.png");
  /* background-size: cover; */
  transition: background-image 0.3sease-in-out;
  top: 0vh;
  position: relative;
  left: -2vh;
}

.opensea:hover {
  background-image: url("./Assets/Yellow-Opensea.png");
}


.iscord {
  width: 250px;
  /* height: 295px; */
  
  cursor: pointer;
  background-image: url("./Assets/Black-Discord.png");
  /* background-size: cover; */
  transition: background-image 0.3s ease-in-out;
  top: -80px;
  position: relative;
}


.iscord:hover {
  background-image: url("./Assets/Yellow-Discord.png");
}

.Xx {
  width: 250px;
  height: 295px;
  
  cursor: pointer;
  background-image: url("./Assets/Black-X.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
  top: -75px;
  position: relative;
}

.Xx:hover {
  background-image: url("./Assets/Yellow-X.png");
}
.wrapcast {
  width: 11.5vw;
  height: 7vh;
  cursor: pointer;
  background-image: url("./Assets/Black\ Warpcast.png");
  background-size: cover;
  transition: background-image 0.3sease-in-out;
  top: 7vh;
  position: relative;

}

.wrapcast:hover {
  background-image: url("./Assets/Yellow\ Warpcast.png");
}






.Bch1 {
  width: 310px;
  height: 295px;
  position: absolute;
  top: 50px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("./Assets/Black-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.Bch1:hover {
  background-image: url("./Assets/Clicked-Chapter1.png");
}
.Bch2 {
  width: 310px;
  height: 295px;
  position: absolute;
  top: 50px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("./Assets/Black-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.Bch2:hover {
  background-image: url("./Assets/Clicked-Chapter2.png");
}
.Bch3 {
  width: 310px;
  height: 295px;
  position: absolute;
  top: 50px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("./Assets/Black-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.Bch3:hover {
  background-image: url("./Assets/Clicked-Chapter3.png");
}
.Bch4 {
  width: 310px;
  height: 295px;
  position: absolute;
  top: 50px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("./Assets/Black-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.Bch4:hover {
  background-image: url("./Assets/Clicked-Chapter4.png");
}
.Bch5 {
  width: 310px;
  height: 295px;
  position: absolute;
  top: 50px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("./Assets/Black-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.Bch5:hover {
  background-image: url("./Assets/Clicked-Chapter5.png");
}
.Bch6 {
  width: 310px;
  height: 295px;
  position: absolute;
  top: 50px;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("./Assets/Black-Read-the-story-.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.Bch6:hover {
  background-image: url("./Assets/Clicked-Chapter6.png");
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .pensea{
    top: 3.8vh;
    position: relative;
    left: -4vh;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .Xx{
    width: 626px;
    height: 164px;
    position: relative;
    top: -38px;
    left: -7vw;
    background-size: 139%;
  }
  .iscord{
    width: 751px;
    height: 152px;
    top: -34px;
    left: -6px;
    position: relative;
    background-size: 110%;
  }
   .opensea {
    width: 12rem;
    height: 9rem;
    left: -3vh;
    top: -2vh;
    position: absolute;
    background-size: cover;
  }
  .wrapcast{
    width: 33rem;
    height: 2rem;
    position: relative;
    top: 4.6vh;
    left: 2vw;
    background-size: cover;
  }
   .Abu{
    width: 11rem;
        height: 8rem;
        position: absolute;
        top: -0.5vh;
        left: 29vw;
  }
   .QA{
    /* width: 500px; */
    height: 161px;
    position: relative;
    top: 82px;
    left: -9px;
  }
   .Don{
    width: 12rem;
    height: 9rem;
    position: relative;
    top: -1.9vh;
    left: 44vw;
    background-size: cover;
  }
  
  /* .Xx{
    width: 499px;
    height: 165px;
    position: relative;
    top: 56px;
    left: -25px;
    background-size: 133%;
    z-index: 1000;

  }
  .iscord{
    width: 537px;
    height: 152px;
    top: 52px;
    left: -41px;
    position: relative;
    background-size: cover;
    z-index: 1000;
  }
  .pensea{
    width: 688px;
    height: 177px;
    position: relative;
    top: 62px;
    left: -12px;
    background-size: 92%;
    z-index: 1000;

  }
  .Abu{
    /* width: 417px; */
    /* height: 106px;
    position: relative;
    top: 99px;
    left: 0px;
  }  */
  .QA{
    width: 480px;
    height: 161px;
    position: relative;
    top: 82px;
    left: -25px;
  }
  /* .Don{
           /* width: 391px; */
           /* height: 128px;
           position: relative;
           top: 102px;
           left: -8px;
  } */
  .see-more {
    position: absolute;
    top: 162px;
    right: 0px;
    left: 57%;
  }
  .GbtnF {
    display: flex;
    justify-content: space-around;
    position: relative;
    left: 3%;
    width: 97%;
    top: 10vh;
    padding-bottom: 40px;
  }
  .GbtnFo {
    display: flex;
    justify-content: space-around;
    position: relative;
    /* left: 16%; */
    width: 79%;
    top: -4vh;
    padding-bottom: 40px;
}
  .GbtnF, .GbtnFo {
    /* width: 97%; */
    left: -1vw;
  }
  .copywrite {
    display: flex;
    justify-content: center;
    position: relative;
    height: 40px;
    font-weight: bold;
    top: -30px;
    font-size: 15px;
    /* background-image: url('./panic_bg.png'); */
  }
  .containerF {
    /* background-image: url('./Assets/darkpanic_bg.png'); */
      /* height: 0vh; */
      position: relative;
      /* top: -538px; */
      margin-top: 570px;
    }
    .item {
      flex: 1;
      width: 40%;
      margin: 0 10px;
      /* Adjust spacing between items */
      text-align: center;
    }
  
    
    .item h2 {
      font-size: 8px;
    }
    .item .asizebr  {
      width: 70px;
    }
    .row-container {
      left: 2.1%;
    }
    .item .asizeb  {
      width: 70px;
      display: flex;
      justify-content: space-around;
  
      /* height: 420px; */
    }
  
    .item .asizea {
      width: 60%;
      /* height: 50px; */
      /* max-width: 200px; */
      /* Adjust the size as needed */
      /* display: block; */
      /* margin: 0 auto; */
    }
.Bch1, .Bch2, .Bch3, .Bch4, .Bch5, .Bch6 {
  position: absolute;
  top: 110px;
  left: 65%;
  /* margin-left: 7%; */
  background-size: cover;
  width: 40%;
  height: 130px;     
}
.Gcontainer {
  /* padding-left: -30px; */
  height: 1770px;
  margin-top: -110px;
}
.GcontainerF {
  /* background-image: url('./Assets/darkpanic_bg.png'); */
    /* height: 10vh; */
    position: relative;
    top: 244px;
  }
  .fcol {
    width: 60px;
  }

  .item img {
    width: 70px;
    height: auto;
    max-width: 200px; /* Adjust the size as needed */
    display: block;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 4000px) and (min-width: 2880px) {
  .Gcontainer{
    height: 202vh;
  }
  .GcontainerF{
    top: -2256px;

  }
}
@media only screen and (max-width: 1999px) and (min-width: 1900px) {
  .GcontainerF{
    top: -158vh;
  }
  .Gcontainer{
    height: 100vh;
  }
  .wrapcast{
    width: 13.5vw;
    height: 7vh;
  }
  .Xx{
    width: 22rem;
    height: 27rem;
    top: -9.4vh;
  }
  .pensea{
    top: 7.6vh;
  }
  .iscord {
    width: 20rem;
    height: 25rem;
    background-size: cover;
    top: -8vh;
}
.opensea {
  width: 24rem;
  height: 24rem;
  background-size: cover;
  top: -5vh;
  left: -2vh;
}
.Abu {
  width: 24rem;
  height: 24rem;
  background-size: cover;
  top: -5vh;
  left: -2vh;
  position: relative;
}
.Don{
    width: 24rem;
    height: 24rem;
    background-size: cover;
    top: -5vh;
    left: -2vh;
}
}

@media only screen and (max-width: 3900px) and (min-width: 2100px) {
  .wrapcast {
    height: 3.1vh;
        width: 6vw;
        top: 2vh;
    }
    .GbtnFo{
      top: 9vh;
    }
}