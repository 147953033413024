@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&family=Press+Start+2P&display=swap');


.Ycont {
  position: relative;
  top: -30px;
  background-size: cover;
  background-image: url('./panic_bg.png');
  background-size: 201%;


   /* color: #FFD21E; */
   font-family: "Anic", sans-serif;
   /* text-shadow: 1px 1px #0A0A0A; */

}
.homenavs .btn .tys img {
  width: 150px; 
  height: 150px;
  position: relative;
  top: -59px;
}
.disc {
  width: 310px;
  height: 295px;
  position: relative;
  top: -20px;
  left: 20%;
  margin-top: -120px;
  cursor: pointer;
  background-image: url("../../Assets/Btns/disc.png");
  background-size: cover;
  transition: background-image 0.3s ease-in-out;
}

.disc:hover {
  background-image: url("../../Assets/Btns/Bdisc.png");
}

.multiply {
  font-size: 32px;
  padding: 7px;
  position: relative;
  top: 4px;
  font-family: "Anic", sans-serif;
}

.homebtn{
  width: 57px;
  position: relative;
  height: 73px;
  top: 3rem;
  left: 10rem;
    background-image: url(/src/components/Home/panic\ yellow.png);
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
}

.homebtn:hover {
  background-image: url(
"/src/components/Home/panic\ black.png");
}
.Ycont .homenavs {
  position: relative;
  top: -230px;
  /* padding-top: 120px; */
  margin-bottom: -70px;
}
.btn {
    display: flex;
    justify-content: space-around;

}
.btn .panicportal:hover {
    background-color: black;
    color: #ce9c11;

}


.panicportal {
    text-decoration: none;
  /* color: black; */
  font-weight: bold;
    border: 1px solid transparent;
    transition: border 0.3s;
    padding: 15px 55px;
    /* height: 14px; */
    background-color: #ce9c11;
    border-radius: 80%;
    /* box-shadow: h-offset v-offset blur spread #f7be22; */
    /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
    font-weight: bold;
    white-space: nowrap;
    position: relative;
    left: 19%;
    height: 25px;
    /* flex: 1; */
}
.btn2 {
    
    text-decoration: none;
    /* color: black; */
    font-weight: bold;
      border: 1px solid transparent;
      transition: border 0.3s;
      /* padding: 15px 55px; */
      /* height: 14px; */
      /* background-color: #ce9c11; */
      border-radius: 80%;
      /* box-shadow: h-offset v-offset blur spread #f7be22; */
      /* box-shadow: '12px 12px 15px rgba(0, 0, 0, 0.3)'; */
      font-weight: bold;
      white-space: nowrap;
      position: relative;
      left: 4%;
}
/* .btn2 Link img:hover {
  background-color: #ce9c11;
} */
.Yintro {
  /* position: absolute; */
  font-weight: bold;
  width: 100%;
  height: 450px;
  position: relative;
  top: -150px;
  /* background-image: url("./panic_bg.png"); */
  background-size: cover;
  background-position: center;
  /* background-repeat: no-repeat; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.bgImg {
  position: absolute;
}
.Yintro p {
  font-size: 27px;
  width: 85%;
  word-spacing: 15px;


  
 
}
.Yhomebtna{
  width: 100px;
  position: relative;
  height: 125px;
  top: 3rem;
  left: 10rem;
    background-image: url(/src/components/Home/panic\ yellow.png);
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
}

.Yhomebtna:hover {
  background-image: url(
"/src/components/Home/panic\ black.png");
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .multiply {
    font-size: 22px;
    padding: 7px;
    position: relative;
    top: 2px;
    
  }
  .tys  {
    /* width: 150px;  */
    /* height: 130px; */
    position: relative;
    top: 89px;
    /* z-index: 0; */
    left: 30px;
  }
  .tys img {
    
  }
  .Ycont {
    height: 1560px;
  }
  .disc{
    width: 160px;
    height: 167px;
    position: relative;
    top: 17px;
    left: 20%;
    margin-top: 0px;
  }
  .Yintro p {
    font-size: 12px;
    width: 85%;
    word-spacing: 12px;
    /* color: black; */
  }
  .Yhomebtna{
    width: 57px;
    position: relative;
    height: 73px;
    top: 8rem;
    left: 2rem;
      background-image: url(/src/components/Home/panic\ yellow.png);
      background-size: cover;
      transition: background-image 0.3s ease-in-out;
  }
  
  .Yhomebtna:hover {
    background-image: url(
  "/src/components/Home/panic\ black.png");
  }
  
  .homenavs .btn .tys img {
      height: 100px;
      position: relative;
      top: -45px;
      left: -.5rem;
      width: 90px;
    
  }
  }