.contr{
    display: flex;
}

button{
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    margin: 0em;
    padding-block: 1px;
    padding-inline: 0px;
    border-width: 0px;
    border-style: none;
    border-color: transparent;
    border-image: none;
    width: 169px;
    height: 40px;
    background: transparent;

}

.close-btn1{
    display: block;
    left: 52vw;
    z-index: 10;
    top: -5rem;
    position: absolute;
    background-size: cover;
    transition: background-image 0.3sease-in-out;
    width: 6.5vh;
    height: 8.2vh;

    cursor: pointer;
    /* background-image:url(/src/components/Base\ logo\ 110\ ×\ 110.png); */
    /* background-image:url(/src/components/closee.png); */
    background-image:url(/src/components/closee.png);
}
.close-btn{
    background-image:url(/src/components/closee.png);
    background-size: cover;
    cursor: pointer;
    display: block;
    height: 141px;
    left: 85vw;
    position: absolute;
    top: 3rem;
    transition: background-image .3sease-in-out;
    width: 110px;
}
.popup{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    z-index: 1000;
    background-color: rgba(0,0,0,0.2);


    display: flex;
    justify-content: center;
    align-items: center;

}
.treasure{
    width: 100vw;
    position: relative;
    height: 27vh;
    top: 53vh;

}

.popup-inner{
   position: relative;
   width: 70vw;
   height: 45vh;
    
    /* background-image: url(/src/components/Treasure\ PC.png); */
    background-size: cover;
    /* background: no-repeat; */
    background-position-y: 289px;
}
.popup-inner .close-btn{
    position:absolute;
    /* top:16px; */
    right:16px;
}

.popupbag{
    display: block;
    left: 70vw;
    top: 3rem;
    position: relative;
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
    width: 69px;
    height: 69px;
    cursor: pointer;
    background-image:url(/src/components/Gift\ box.gif);
}
.popupbags{
    display: block;
    left: 75vw;
    top: 8rem;
    position: relative;
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
    width: 95px;
    height: 97px;
    cursor: pointer;
    background-image:url(/src/components/Gift\ box.gif);
}

.form{
    display: flex;
    background-image: url(./BackgroundformPC.png);
    background-size: cover;
    justify-content: space-between;
    /* align-content: stretch; */
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
}
.username, address{
    flex-wrap: wrap;
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    left: 33vw;
    position: relative;
    width: 34vw;
    top: 33vh;
}
address{
    top: 38vh;
}
.address1{
    display: flex;
    flex-direction: column;
    width: 0vw;
    position: relative;
    left: 31vw;
    top: 22vh;
    align-content: center;
    flex-wrap: wrap;


}
.buttonform1{
    display: block;
    /* left: 70vw; */
    /* top: 3rem; */
    position: relative;
    background-size: contain;
    transition: background-image 0.3s ease-in-out;
    width: 176px;
    height: 29px;
    cursor: pointer;
    /* background-image:url(/src/components/Send\ Button.png); */
    position: relative;
    /* left: 24vw; */
    top: 32vh;
    left: 26vw;
}

.address{
    display: flex;
    flex-direction: column;
    left: 29vw;
    position: relative;
    width: 34vw;
    top: 38vh;


}
.buttonform{
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 29px;
    left: 45vw;
    position: relative;
    top: 44vh;
    transition: background-image .3sease-in-out;
    width: 176px;
}
.usernametitle{
    position: relative;
    /* left: 104px; */
    text-align: center;
    margin-bottom: 9px;
    font-size: 27px;
    color: #141414;
    /* font-size: 27px; */
}
.addresstitle{
    position: relative;
    display: flex
;
    flex-wrap: wrap;
    /* left: 134px; */
    margin-bottom: 9px;
    font-size: 27px;
    text-align: center;
    /* width: 40vw; */
    color: #141414;
    align-items: center;
    justify-content: center;
}
.usernameinput{
    width: 38rem;
    height: 1.3rem;
    /* font-size: 14px; */
    border: 2px solid transparent;
    background-color:  #F5C305;
    /* color: black; */
    font-size: 11px;;
    font-weight: bold;
    text-align: center;
    outline: none;
    box-shadow: inset 3px 2px 8px #b08613, inset 3px -2px 15px #ce9c11;
}
.addressinput{
    width: 38rem;
    height: 1.3rem;
    /* font-size: 14px; */
    border: 2px solid transparent;
    background-color:  #F5C305;
    /* color: black; */
    font-size: 11px;;
    font-weight: bold;
    text-align: center;
    outline: none;
    box-shadow: inset 2px 2px 0px #B39315, inset -1px -2px 1px #d6a82a;
}
.error{
    position: absolute;
    top: 10vh;
    left: 50%;
    width: 45vw;
    transform: translate(-50%, -50%);
    /* background: #d0d0d0; */
    padding: 1vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1em;
    color: #d0d0d0;
    flex-wrap: wrap;
    justify-content: center;
}

.kingimage{
    height: 100px;
    width:100px;
    background-size: contain;
    height: 166px;
    width: 91px;
    background-size: contain;
    position: relative;
    top: 16vh;
    left: 140px;
}
.kingandqueen{
    display: flex;
    width: 60vw
}

.queenimage{
    height: 100px;
    width:100px;
    background-size: contain;
    height: 166px;
    width: 91px;
    background-size: contain;
    position: relative;
    top: 16vh;
    left: 105vh;

}

.discord-icon {
    position: absolute;
    top: 8vh;
    right: 53vw;
    width: 82px;
    height: 79px;
    cursor: pointer;
    background: none;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: none;

  }
  .discord-image{
    width: 13rem;
    height: 9rem;
    position: relative;
    left: 1.5vw;
  }
  
  .discord-icon svg {
    width: 100%;
    height: 100%;
  }
  .discord-icon:focus {
    outline: none;
  }
  
  .discord-icon:active {
    outline: none;
    border: none;
  }
  .treasuremobile{
    visibility: hidden;
  }
  
  

@media only screen and (max-width: 369px) and (min-width: 320px) {
           .kingimage {
        height: 106px !important;
        top: 248px !important;
       }
       .queenimage{
        top: 248px !important;

       }
}
@media screen and (max-width: 345px), 
       screen and (max-height: 705px){
    .popup-inner{
        height: 76vh !important;
        /* width: 84vw !important; */
    }
}
@media screen and (max-width: 345px), 
       screen and (max-height: 580px){
    .popup-inner{
        height: 93vh !important;
        width: 84vw !important;
    }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .form{
        overflow: hidden;
        height: 100vh;
    }
    .treasure{
        visibility: hidden;
        height: 0vh;
    }
    .treasuremobile{
        visibility: visible;
        width: 100vw;
        position: relative;
        /* height: 18vh; */
        top: 0vh;
    }

    .discord-image{
        width: 11rem;
    height: 8rem;
    position: relative;
    left: 1.5vw;
    top: 3rem;

    }
    .close-btn{
        left: 76vw;
    top: 1rem;
    width: 73px;
    height: 94px;
    }
    .popup-inner{
        background-size: contain;
        background-position-y: 45vh;
        background-repeat: no-repeat;
        height: 67vh;
    }
    .popupbag{
        left: 26vw;
    }
    .username{
        left: -11vw;
        width: 85vw;
        top: 32vh;
    }
    .usernameinput {
        width: 21rem;
        left: 72px;
        position: relative;
        height: 15px;
}
.usernametitle{
    left: 20vw;
        font-size: 19px;
        text-align: center;
}
.address{
    left: -4vw;
    top: 30vh;
}
.addressinput {
    width: 21rem;
    left: 39px;
    position: relative;
    height: 15px;

}
.addresstitle{
    margin-bottom: 11px;
    font-size: 19px;
        width: 113vw;
        text-align: center;
}
.error{
    width: 83vw;
    font-size: 0.7em;
    
}
.buttonform{
    top: 28vh;
    left: 28vw;

}
.buttonform1{
    top: 28vh;
    left: 28vw;

}
.kingimage{
    height: 99px;
    width: 61px;
    top: 19vh;
    left: 25px;
    

}
.queenimage{
    height: 99px;
        width: 61px;
        top: 19vh;
        left: 46vw;

}
.popupbags{
    left: 80vw;
    top: 1px;
    width: 35px;
    height: 33px;
}
}
@media only screen and (max-width: 2500px) and (min-width: 1900px) {
    .address {
      left: 36vw;
  
    }
  }
@media only screen and (max-width: 2100px) and (min-width: 1802px) {
    .popup-inner{
        background-position-y: -148px;
        height: 86vh;
    }
    .queenimage{
        height: 256px;
        width: 139px;
        top: 30vh;
        left: 49vw;

    
    }
    .kingimage{
        height: 282px;
        width: 146px;

    top: 29vh;
    left: 11vw;
    }
    .username{
        left: 39vw;
        top: 30vh;

    }
    .usernameinput{
        width: 51rem;
    position: relative;
    left: -7rem;
    }
    .usernametitle {
        left: -5vw;

    }
    .addresstitle{
        left: -5vw;

    }
    .address{
        left: 39vw;
    }
    .addressinput{
        width: 51rem;
    position: relative;
    left: -7rem;
    }
    .buttonform{
        /* left: 40vw; */
        top: 49vh;
        width: 237px;
        height: 46px;
      }
      .error {
      left: 37%
      }
}
@media only screen and (max-width: 1900px) and (min-width: 1800px) {
    .popup-inner{
        height: 73vh;
    }
    .queenimage{
        height: 196px;
    width: 120px;
    background-size: contain;
    position: relative;
    top: 218px;
    left: 891px;
    }
    .kingimage{
        height: 196px;
        width: 122px;
    
    }
    .username{
        width: 42vw;
        position: relative;
        left: 24vw;
        top: 8vh;
    }
    .usernametitle{
        left: -3vw;
        text-align: center;
        margin-bottom: 9px;
        font-size: 35px;    
    }
    .usernameinput{
        width: 567px;
    left: -7vh;
    }
    .address{
        top: 37vh;
    }
    .addresstitle{
        font-size: 35px;
        left: 3vw;

    }
    .addressinput{
        width: 630px;
        height: 28px;
        position: relative;
        left: 4vh;
    }
    .buttonform{
        top: 45vh;

        left: 36vw;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1900px) {
.treasure{
    top: 56vh;
}
}
@media only screen and (max-width: 1800px) and (min-width: 1700px) {
    .treasure{
        top: 58vh;
    }
    .buttonform{
        top: 50vh;
    left: 45vw;
}
    .username {
        left: 29vw;
        position: relative;
        top: 36vh;
        width: 42vw;
    }
    .discord-icon{
        top: 13vh;
        right: 54vw;
    }
    .usernameinput {
        /* left: -6vh; */
        width: 46vw;
        position: relative;
    }
    .address1{
        top: 25vh;
        left: 31vw;
}
    .address{
        left: 27vw;
        position: relative;
        width: 34vw;
        top: 44vh;
    
}
.buttonform1{
    top: 34vh;
    left: 26vw;

}
    .addressinput{
             /* left: -6vh; */
            width: 46vw;
            position: relative;
        
    }
}
@media only screen and (max-width: 4000px) and (min-width: 2880px) {
    .popup-inner .close-btn{
        top: 133px;
    }
    .close-btn{
        left: 71vw;
        width: 13rem;
        height: 13rem;
    }
    .usernametitle{
        left: 209px;
    font-size: 104px;
    }
    .usernameinput{
        width: 46vw;
    left: -5vw;
    position: relative;
    top: 0vw;
    height: 80px;
    }
    .addresstitle{
        font-size: 104px;
    }
    .addressinput{
        width: 46vw;
    left: -5vw;
    position: relative;
    top: 0vw;
    height: 80px;
    }
    .buttonform{
        top: 29vh;
    left: 29vw;
    width: 19vw;
    height: 3vw;
    }
    .kingimage{
        height: 25vh;
    width: 7vw;
    top: 27vh;
    left: 13vw;
    }
    .queenimage{
        height: 25vh;
    width: 7vw;
    background-size: contain;
    position: relative;
    top: 27vh;
    left: 51vw;
    }
}
